import styled from 'styled-components';

interface RedirectProps {
  color?: string;
}

export const Redirect = styled.a<RedirectProps>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;

  text-decoration: underline;
  cursor: pointer;
  color: ${(props) => (props.color ? props.color : '#000000')};
`;
