import React from 'react';
import { Description, MainWrapper, Title } from './NotFound.styles';

const NotFound: React.FC = () => {
  return (
    <MainWrapper data-testid="not-found">
      <Description>We couldn&apos;t find the page you were looking for.</Description>
      <Title>404</Title>
    </MainWrapper>
  );
};

export { NotFound };
