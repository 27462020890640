import styled from 'styled-components';
import { ButtonProps } from './Button';

export const StyledButton = styled.button<ButtonProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;

  cursor: pointer;

  /* position: absolute; */
  left: 23.4%;
  right: 50.07%;
  top: 66.99%;
  bottom: 28.32%;

  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;

  text-align: center;

  background: ${(props) => (props.variant === 'primary' ? '#c71010' : '#FFFFFF')};
  color: ${(props) => (props.variant === 'primary' ? '#FFFFFF' : '#000000')};
  border: ${(props) => (props.variant === 'primary' ? 'none' : 'block')};
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08);
  border-radius: 100px;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
