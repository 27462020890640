import axios from 'axios';
import { IClaim } from '../../../models/claim';

interface IAxiosData {
  status: number;
  data: any;
}

const postClaimService = async (
  baseURL: string,
  claimData: IClaim,
  authorizationKey: string
): Promise<IAxiosData> => {
  const { status, data } = await axios.post(`${baseURL}/api/nft/claim`, claimData, {
    headers: { 'claim-authorization-app': authorizationKey },
  });
  return { status, data };
};

export { postClaimService };
