import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Claim } from './pages/claim/Claim';
import { NotFound } from './pages/NotFound/NotFound';

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/:brand/:id" element={<Claim />} />
      <Route path="/notfound" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/notfound" replace />} />
    </Routes>
  );
};
export default Router;
