import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
`;

export const StyledInput = styled.input`
  width: calc(100% - 16px);

  border-radius: 8px;
  border: 1px solid #00000026;
  color: #000000;
  outline: none;
  padding: 12px 0 12px 16px;

  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;

  &::placeholder {
    color: rgba(0, 0, 0, 0.55);
  }
`;

export const StyledLabel = styled.label`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;

  margin-bottom: 4px;
`;

export const ErrorSpan = styled.span`
  margin: 0;
  padding: 0;
  color: red;
`;
