import { useLocation } from 'react-router-dom';

interface IUseQueryParams {
  nftParams: string;
  purchaseIdParams: string;
}

const useQueryParams = (): IUseQueryParams => {
  const { search } = useLocation();
  const searchParms = new URLSearchParams(search);

  const nftParams = searchParms.get('nft');
  const purchaseIdParams = searchParms.get('purchaseid');

  const nftCount = nftParams ? nftParams : '';
  const purchaseId = purchaseIdParams ? purchaseIdParams : '';

  return { nftParams: nftCount, purchaseIdParams: purchaseId };
};

export { useQueryParams };
