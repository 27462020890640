import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;

  @media (max-width: 768px) {
    margin-top: 25px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
`;

interface ILabelProps {
  isPreview?: boolean;
}
export const ClickableLabelText = styled.div<ILabelProps>`
  margin-top: 20px;
  cursor: ${(props) => (props.isPreview ? 'none' : 'pointer')};
  pointer-events: ${(props) => (props.isPreview ? 'none' : 'auto')};
  width: 268px;
  height: 24px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.9);
  flex: none;
  flex-grow: 0;

  @media (max-width: 768px) {
    margin-bottom: 25px;
  }
`;
