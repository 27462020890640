interface IEnvironmentVariables {
  BASE_URL: string;
  CLAIM_API_KEY: string;
}

const localVariables: IEnvironmentVariables = {
  BASE_URL: 'https://services-dev.bees-platform.dev/nftclaim-service',
  CLAIM_API_KEY: '1f4dc9c73751653a7d5e825a81c91d75',
};

const devVariables: IEnvironmentVariables = {
  BASE_URL: 'https://services-dev.bees-platform.dev/nftclaim-service',
  CLAIM_API_KEY: '1f4dc9c73751653a7d5e825a81c91d75',
};

const sitVariables: IEnvironmentVariables = {
  BASE_URL: 'https://services-sit.bees-platform.dev/nftclaim-service',
  CLAIM_API_KEY: '1f4dc9c73751653a7d5e825a81c91d75',
};

const uatVariables: IEnvironmentVariables = {
  BASE_URL: 'https://services-uat.bees-platform.dev/nftclaim-service',
  CLAIM_API_KEY: '1f4dc9c73751653a7d5e825a81c91d75',
};

const prodVariables: IEnvironmentVariables = {
  BASE_URL: 'https://services.bees-platform.dev/nftclaim-service',
  CLAIM_API_KEY: '1f4dc9c73751653a7d5e825a81c91d75',
};

const envProvider = (): IEnvironmentVariables => {
  const { origin } = window;

  switch (origin) {
    case 'https://claim-dev.bees-platform.dev':
      return devVariables;

    case 'https://claim-sit.bees-platform.dev':
      return sitVariables;

    case 'https://claim-uat.bees-platform.dev':
      return uatVariables;

    case 'https://claim.bees-platform.dev':
      return prodVariables;

    default:
      return localVariables;
  }
};

export { envProvider };
