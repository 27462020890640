import { ErrorToastIcon } from '../../assets/icons/ErrorToastIcon';
import { CloseButton, ToastMessage, ToastWrapper } from './ErrorToast.styles';
interface IModal {
  setIsOpen: Function;
  message: string;
}

export const ToastError = ({ setIsOpen, message }: IModal) => {
  return (
    <ToastWrapper data-testid="toast-error">
      <ErrorToastIcon />
      <ToastMessage>{message}</ToastMessage>
      <CloseButton data-testid="close-button" onClick={() => setIsOpen(false)}>
        Close
      </CloseButton>
    </ToastWrapper>
  );
};
