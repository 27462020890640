import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { theme } from '../../assets/styles/theme';
import { CardsWrapper, MainWrapper } from './Claim.styles';
import { FormCard } from './components/FormCard/FormCard';
import { InstructionCard } from './components/InstructionCard/InstructionCard';
import { useValidateParams } from './hooks/useValidateParams/useValidateParams';

const Claim: React.FC = () => {
  const { brand, id } = useParams();
  const navigate = useNavigate();
  const { isUuid, nftCount, hasPurchaseId, isPreview } = useValidateParams(id || '');
  const authorizedBrand = useMemo(() => {
    return ['budweiser', 'budlight'];
  }, []);

  useEffect(() => {
    if (!isPreview && (!isUuid || nftCount === 0 || !hasPurchaseId)) navigate('/notfound');
  }, [isUuid, navigate, nftCount, hasPurchaseId, isPreview]);

  useEffect(() => {
    if (brand && id) {
      if (!authorizedBrand.includes(brand)) navigate('/notfound');
      else {
        let link = document.querySelector<any>("link[rel='icon']");
        let title = document.querySelector<any>('title');
        /* istanbul ignore next */
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        /* istanbul ignore next */
        if (!title) {
          title = document.createElement('title');
          document.getElementsByTagName('head')[0].appendChild(title);
        }

        title.innerHTML = `${brand.charAt(0).toUpperCase() + brand.slice(1)} | Claim NFT`;
        link.href = `/${brand}.ico`;
      }
    } else navigate('/notfound');
  }, [authorizedBrand, brand, id, navigate, isPreview]);

  /* istanbul ignore next */
  const logo = (brand: string = 'budweiser') => {
    if (Object.keys(theme).includes(brand)) {
      return theme[brand].LogoImage();
    }
  };

  /* istanbul ignore next */
  const mainWrapperStyle = (brand: string = 'budweiser') => {
    if (Object.keys(theme).includes(brand)) {
      return {
        backgroundColor: theme[brand].backgroundColor,
      };
    }
  };

  return (
    <MainWrapper style={mainWrapperStyle(brand)}>
      {logo(brand)}
      <CardsWrapper>
        <FormCard />
        <InstructionCard color="rgba(255, 255, 255, 0.95)" backgroundColor="#12274B">
          Connect your Ethereum wallet or provide your public address to ensure that your NFTs are
          safely delivered to your Ethereum account. <br />
          <br />
          The wallet address is a 42 character hexadecimal key located in your non-custodial wallet,
          such as MetaMask.
          <br />
          <br />
        </InstructionCard>
      </CardsWrapper>
    </MainWrapper>
  );
};

export { Claim };
