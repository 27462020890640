import { ErrorSpan, InputContainer, StyledInput, StyledLabel } from './Input.styles';
import { InputProps } from './types';

const Input = ({
  name,
  placeholder,
  label,
  error,
  errorMessage,
  maxLength,
  setValue,
  value,
  disable,
}: InputProps) => {
  /* istanbul ignore next */
  const changeHandler = (e: React.ChangeEvent<any>) => {
    setValue((prevValues) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  return (
    <InputContainer data-testid="input-container">
      <StyledLabel data-testid="label">{label}</StyledLabel>
      <StyledInput
        placeholder={placeholder}
        name={name}
        onChange={changeHandler}
        maxLength={maxLength}
        data-testid="input"
        value={value}
        disabled={disable}
      />
      {error && <ErrorSpan data-testid="error-span">{errorMessage}</ErrorSpan>}
    </InputContainer>
  );
};

export { Input };
