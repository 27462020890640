import React from 'react';
import { StyledButton } from './Button.styles';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: string;
  variant?: 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'large';
  elevated?: boolean;
  leading?: boolean;
  trailing?: boolean;
  icon?: React.ElementType;
}

export const ButtonComponent = (
  { variant = 'primary',
  size = 'large',
  elevated = false,
  children,
  ...props }: ButtonProps,
  forwardedRef: React.ForwardedRef<HTMLButtonElement>
): JSX.Element => (
  <StyledButton
    data-testid="button"
    variant={variant}
    size={size}
    elevated={elevated}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </StyledButton>
);

export const Button = React.forwardRef(ButtonComponent);
export default Button;
