import styled from 'styled-components';

interface WrapperProps {
  bgColor?: string;
}

interface ParagraphProps {
  color?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  width: calc(100% - 5%);
  max-width: 500px;
  padding: 5%;

  box-shadow: 0px 4px 24px 0px #00000030;
  border-radius: 0px 16px 16px 0px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#FFFFFF')};

  @media (max-width: 768px) {
    width: calc(100% - 10%);
    max-width: 100%;
    padding: 5% 10%;
    box-shadow: 0;
    border-radius: 0;
  }
`;

export const Paragraph = styled.p<ParagraphProps>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${(props) => (props.color ? props.color : '#000000')};

  margin: 0;
`;
