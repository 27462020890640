import { Redirect } from './Redirect.styles';

interface RedirectLabelProps {
  children: Array<React.ReactNode> | React.ReactNode;
  link: string;
  color?: string;
}

const RedirectLabel = ({ children, link, color }: RedirectLabelProps) => {
  return (
    <Redirect data-testid="redirect" href={link} target="_blank" color={color}>
      {children}
    </Redirect>
  );
};

export { RedirectLabel };
