const ErrorToastIcon = () => {
  return (
    <div style={{ margin: '0 10px 0 0' }}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="mask0_2765_109818" maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="18">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.61273 3H15.3873C15.6043 3 15.8124 3.0862 15.9658 3.23964L20.7604 8.03419C20.9138 8.18762 21 8.39573 21 8.61273V15.3873C21 15.6043 20.9138 15.8124 20.7604 15.9658L15.9658 20.7604C15.8124 20.9138 15.6043 21 15.3873 21H8.61273C8.39573 21 8.18762 20.9138 8.03419 20.7604L3.23964 15.9658C3.0862 15.8124 3 15.6043 3 15.3873V8.61273C3 8.39573 3.0862 8.18762 3.23964 8.03419L8.03419 3.23964C8.18762 3.0862 8.39573 3 8.61273 3ZM19.3636 8.95227L15.0477 4.63636H8.95145L4.63636 8.95145V15.0477L8.95227 19.3636H15.0469L19.3636 15.0469V8.95227ZM12 7.90909C12.4196 7.90909 12.7654 8.22494 12.8127 8.63186L12.8182 8.72727V12C12.8182 12.4519 12.4519 12.8182 12 12.8182C11.5804 12.8182 11.2346 12.5023 11.1873 12.0954L11.1818 12V8.72727C11.1818 8.2754 11.5481 7.90909 12 7.90909ZM12.8264 15.2727C12.8264 14.8209 12.4601 14.4545 12.0082 14.4545L11.9046 14.46C11.4977 14.5073 11.1818 14.8531 11.1818 15.2727C11.1818 15.7246 11.5481 16.0909 12 16.0909L12.1036 16.0854C12.5105 16.0381 12.8264 15.6923 12.8264 15.2727Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_2765_109818)">
          <rect width="24" height="24" fill="black" />
        </g>
      </svg>
    </div>
  );
};

export { ErrorToastIcon };
