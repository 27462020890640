import { validate, version } from 'uuid';
import { validatePreview } from '../../../../utils/validatePreview';
import { useQueryParams } from '../useQueryParams/useQueryParams';

interface IUseValidateParams {
  nftCount: number;
  uuid: string;
  isUuid: boolean;
  hasPurchaseId: boolean;
  isPreview: boolean;
}

const useValidateParams = (uuid: string): IUseValidateParams => {
  const { nftParams, purchaseIdParams } = useQueryParams();

  let hasPurchaseId = true;

  const isUuid = validate(uuid) && version(uuid) === 4;
  const isPreview = validatePreview(uuid);

  let nftCount = Number(nftParams);

  if (!nftCount || nftCount < 0) {
    nftCount = 0;
  }

  if (!purchaseIdParams) {
    hasPurchaseId = false;
  }

  return { nftCount, uuid, isUuid, hasPurchaseId, isPreview };
};

export { useValidateParams };
