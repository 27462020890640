import styled from 'styled-components';

export const ToastWrapper = styled.div`
  display: flex;
  flex: 1;
  position: absolute;
  flex-direction: row;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0%);
  position: absolute;
  width: 452px;
  height: 48px;
  background: #ffdede;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 0px 10px 0px 10px;

  @media (max-width: 1440px) {
    bottom: 0px;
  }

  @media (max-width: 1024px) {
    bottom: 10px;
  }

  @media (max-width: 768px) {
    margin-left: 10px;
    width: calc(100% - 10%);
    max-width: 100%;
    bottom: 0px;
    top: 32px;
  }
`;

export const ToastMessage = styled.p`
  width: auto;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.9);
  flex-grow: 1;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  width: auto;
  height: 16px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #000000;
`;
