import styled from 'styled-components';
import { NftWallet } from '../../assets/icons/NftWallet';

export const ModalWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
`;

export const CenteredModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 768px) {
    bottom: 0;
    left: 0;
    transform: translate(0%, 70%);
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 416px;
  height: 457px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);

  @media (max-width: 768px) {
    width: 100vw;
    height: 273px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 32px 32px 0 32px;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding-top: 60px;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 768px) {
    align-items: flex-start;
    padding-left: 32px;
  }
`;

export const WalletIcon = styled(NftWallet)`
  display: flex;
  height: 100px;
  max-width: 200px;
  color: black;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ModalFooter = styled.div`
  height: auto;
  margin-bottom: 48px;
  width: 100%;
  padding: 0 130px 0 130px;

  @media (max-width: 768px) {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  @media (max-width: 425px) {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const ModalTitle = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
`;

export const ParagaphWrapper = styled.div``;

export const ModalParagraph = styled.div`
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  max-width: 260px;

  @media (max-width: 768px) {
    max-width: 100vw;
  }

  @media (max-width: 425px) {
    max-width: 85vw;
    text-align: start;
  }
`;

export const CloseBtn = styled.button`
  cursor: pointer;
  width: 36px;
  height: 36px;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 16px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: white;
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.15);
  align-items: center;
  align-content: center;
  justify-content: center;
  &:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  }
`;
