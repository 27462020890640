import { RedirectLabel } from '../../../../components/RedirectLabel/RedirectLabel';
import { ContactUsWrapper } from '../../Claim.styles';
import { Paragraph, Wrapper } from './InstructionCard.styles';

interface InstructionCardProps {
  children: Array<React.ReactNode> | React.ReactNode;
  color?: string;
  backgroundColor?: string;
}

const InstructionCard = ({ children, color, backgroundColor }: InstructionCardProps) => {
  return (
    <Wrapper bgColor={backgroundColor} data-testid="instruction-card">
      <Paragraph color={color} data-testid="instruction-paragraph">
        {children}
      </Paragraph>
      <ContactUsWrapper>
        <RedirectLabel link="https://info.etherscan.com/what-is-an-ethereum-address/" color={color}>
          More about Ethereum address
        </RedirectLabel>
      </ContactUsWrapper>
    </Wrapper>
  );
};

export { InstructionCard };
