import { BudLightLogo } from '../budlight/BudLightLogo';
import { BudweiserLogo } from '../budweiser/BudweiserLogo';

export interface IStyles {
  LogoImage: () => JSX.Element;
  backgroundColor: string;
  backgroundImage?: string;
  buttons: {
    primary: {
      bgColor: string;
      hoveredBgColor?: string;
      textColor: string;
      hoveredTextColor?: string;
      border?: string;
      hoveredBorder?: string;
    };
    secondary: {
      bgColor: string;
      hoveredBgColor?: string;
      textColor: string;
      hoveredTextColor?: string;
      border: string;
      hoveredBorder?: string;
    };
  };
}

const theme: { [keys: string]: IStyles } = {
  budweiser: {
    LogoImage: BudweiserLogo,
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    backgroundImage: '',
    buttons: {
      primary: {
        bgColor: '#C71010',
        hoveredBgColor: 'green',
        textColor: 'rgba(255, 255, 255, 0.95)',
        hoveredTextColor: 'red',
        border: 'none',
        hoveredBorder: 'border: 1px solid green',
      },
      secondary: {
        bgColor: '#FFFFFF',
        hoveredBgColor: 'green',
        textColor: 'rgba(0, 0, 0, 0.9)',
        hoveredTextColor: 'red',
        border: 'border: 1px solid #000000',
        hoveredBorder: 'border: 1px solid gray',
      },
    },
  },
  budlight: {
    LogoImage: BudLightLogo,
    backgroundColor: '#00A1E1',
    backgroundImage: '',
    buttons: {
      primary: {
        bgColor: '#021F8E',
        hoveredBgColor: 'green',
        textColor: 'rgba(255, 255, 255, 0.95)',
        hoveredTextColor: 'red',
        border: 'none',
        hoveredBorder: 'border: 1px solid green',
      },
      secondary: {
        bgColor: '#FFFFFF',
        hoveredBgColor: 'green',
        textColor: 'rgba(0, 0, 0, 0.9)',
        hoveredTextColor: 'red',
        border: 'border: 1px solid #000000',
        hoveredBorder: 'border: 1px solid gray',
      },
    },
  },
};

export { theme };
