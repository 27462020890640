import { useMetaMask } from 'metamask-react';
import { useEffect, useMemo } from 'react';

interface IMetamaskHook {
  metaMaskStatus: string;
  walletAddress: string | null;
  connectMetamask: Function;
}

const useMetaMaskHook = (): IMetamaskHook => {
  const { status, connect, account, chainId, switchChain } = useMetaMask();

  useEffect(() => {
    if (status === 'connected' && chainId !== '0x1') switchChain('0x1');
  }, [chainId, status, switchChain]);

  const metaMaskStatus = useMemo(() => {
    let metamaskConnectionStatus = '';

    switch (status) {
      case 'initializing':
        metamaskConnectionStatus = 'Synchronization with MetaMask ongoing...';
        break;
      case 'unavailable':
        metamaskConnectionStatus = 'MetaMask not available';
        break;
      case 'notConnected':
        metamaskConnectionStatus = 'Connect to MetaMask';
        break;
      case 'connecting':
        metamaskConnectionStatus = 'Connecting...';
        break;
      case 'connected':
        metamaskConnectionStatus = `Connected account ${account} on chain ID ${chainId}`;
        break;

      default:
        break;
    }
    return metamaskConnectionStatus;
  }, [account, chainId, status]);

  return {
    metaMaskStatus,
    walletAddress: account,
    connectMetamask: connect,
  };
};

export { useMetaMaskHook };
