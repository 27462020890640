import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { theme } from '../../assets/styles/theme';
import { useMetaMaskHook } from '../../hooks/useMetaMask';
import { validateEthereumAddres } from '../../utils/validateEthereumAddress';
import Button from '../Button/Button';
import { Input } from '../Input/Input';
import { ClickableLabelText, InputWrapper, Wrapper } from './ClickableLabel.styles';
interface IClickableLabel {
  setWallet: Dispatch<
    SetStateAction<{
      email: string;
      walletAddress: string;
    }>
  >;
  wallet: string;
  isPreview: boolean;
}
const ClickableLabel = ({ setWallet, wallet, isPreview }: IClickableLabel): JSX.Element => {
  const [showLabel, setShowLabel] = useState(true);
  const [invalidWalletAddress, setInvalidWalletAddress] = useState(false);
  const { connectMetamask, walletAddress } = useMetaMaskHook();
  const { brand } = useParams();

  useEffect(() => {
    if (wallet.length === 42 && !validateEthereumAddres(wallet)) {
      setInvalidWalletAddress(true);
    } else {
      setInvalidWalletAddress(false);
    }
  }, [wallet]);

  const enableInput = () => {
    setShowLabel((prev) => !prev);
  };

  const buttonStyle = (brand: string = 'budweiser') => {
    if (Object.keys(theme).includes(brand)) {
      return {
        backgroundColor: theme[brand].buttons.secondary.bgColor,
      };
    }
  };

  return (
    <Wrapper>
      {showLabel && (
        <Button
          variant="secondary"
          disabled={!!walletAddress || isPreview}
          style={buttonStyle(brand)}
          onClick={() => connectMetamask()}
        >
          {walletAddress
            ? `${walletAddress.slice(0, 6)}...${walletAddress.slice(walletAddress.length - 4)}`
            : 'Connect your wallet'}
        </Button>
      )}
      {showLabel && (
        <ClickableLabelText
          data-testid="clickable-label-text"
          onClick={enableInput}
          isPreview={isPreview}
        >
          Enter Ethereum address manually
        </ClickableLabelText>
      )}
      {!showLabel && (
        <InputWrapper>
          <Input
            placeholder="Your 42 character hexadecimal address"
            name="walletAddress"
            label="Ethereum address"
            value={wallet}
            setValue={setWallet}
            error={invalidWalletAddress}
            maxLength={42}
            errorMessage="Invalid Ethereum address. Try again."
          />
          <ClickableLabelText data-testid="clickable-label-wallet" onClick={enableInput}>
            Connect with your wallet
          </ClickableLabelText>
        </InputWrapper>
      )}
    </Wrapper>
  );
};

export { ClickableLabel };
