import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { theme } from '../../../../assets/styles/theme';
import Button from '../../../../components/Button/Button';
import { ClickableLabel } from '../../../../components/ClickableLabelComponent/ClickableLabelComponent';
import { ToastError } from '../../../../components/ErrorToast/ErrorToast';
import { Input } from '../../../../components/Input/Input';
import { Modal } from '../../../../components/Modal/Modal';
import { RedirectLabel } from '../../../../components/RedirectLabel/RedirectLabel';
import { SpinnerComponent } from '../../../../components/Spinner/Spinner';
import { useMetaMaskHook } from '../../../../hooks/useMetaMask';
import { IClaim } from '../../../../models/claim';
import { validateEmail } from '../../../../utils/validateEmail';
import { validatePreview } from '../../../../utils/validatePreview';
import { ContactUsText, ContactUsWrapper } from '../../Claim.styles';
import { useClaim } from '../../hooks/useClaim/useClaim';
import { useQueryParams } from '../../hooks/useQueryParams/useQueryParams';
import { Wrapper } from './FormCard.styles';

const FormCard = () => {
  const { brand, id } = useParams();
  const { nftParams, purchaseIdParams } = useQueryParams();
  const claimHook = useClaim();
  const [showModal, setShowModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showError, setShowError] = useState(false);
  const [data, setData] = useState({ email: '', walletAddress: '' });
  const { walletAddress } = useMetaMaskHook();
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const isPreview = validatePreview(id);

  const buttonStyle = (brand: string = 'budweiser') => {
    if (Object.keys(theme).includes(brand)) {
      return {
        backgroundColor: theme[brand].buttons.primary.bgColor,
      };
    }
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (data.email.length > 0 && !validateEmail(data.email)) {
      setInvalidEmail(true);
    } else {
      setInvalidEmail(false);
    }
  }, [data.email]);

  const claimData: IClaim = useMemo(() => {
    let currentWalletAddress = '';
    if (data.walletAddress) {
      currentWalletAddress = data.walletAddress;
    } else if (walletAddress) {
      currentWalletAddress = walletAddress;
    }

    return {
      campaignId: id || '',
      email: data.email,
      walletAddress: currentWalletAddress,
      nftCount: Number(nftParams),
      purchaseId: purchaseIdParams,
    };
  }, [data, id, nftParams, purchaseIdParams, walletAddress]);

  const handleClaim = useCallback(async () => {
    setShowSpinner(true);
    const { status } = await claimHook(claimData);
    setShowSpinner(false);
    if (status === 200) {
      setShowModal(true);
    } else {
      setToastMessage(() => 'Oops, something went wrong! Please try again.');
      setShowError(true);
    }
  }, [claimData, claimHook]);

  return (
    <Wrapper>
      {showSpinner && <SpinnerComponent />}
      {showModal && <Modal setIsOpen={setShowModal} brand={brand || 'budweiser'} />}
      {showError && <ToastError setIsOpen={setShowError} message={toastMessage} />}
      <Input
        placeholder="Your order email address"
        name="email"
        label="Email"
        setValue={setData}
        error={invalidEmail}
        errorMessage="Invalid email. Try again."
        value={data.email}
        disable={isPreview}
      />
      <ClickableLabel wallet={data.walletAddress} setWallet={setData} isPreview={isPreview} />
      <Button
        disabled={
          isPreview ||
          data.email.length === 0 ||
          invalidEmail ||
          !claimData.walletAddress ||
          claimData.walletAddress.length < 42
        }
        style={buttonStyle(brand)}
        onClick={handleClaim}
      >
        Claim NFT
      </Button>
      <ContactUsWrapper data-testid="contact-us-wrapper">
        <ContactUsText>Need help?&nbsp;</ContactUsText>
        <RedirectLabel link="https://us.budweiser.com/">Contact us here</RedirectLabel>
      </ContactUsWrapper>
    </Wrapper>
  );
};

export { FormCard };
