import { useCallback } from 'react';
import { envProvider } from '../../../../envProvider';
import { IClaim } from '../../../../models/claim';
import { postClaimService } from '../../service/claimService';

interface IHook {
  status: number;
  errorMessage: string;
}

const useClaim = () => {
  return useCallback(async (claimData: IClaim): Promise<IHook> => {
    const { BASE_URL, CLAIM_API_KEY } = envProvider();

    try {
      const { status } = await postClaimService(BASE_URL, claimData, CLAIM_API_KEY);
      return { status, errorMessage: '' };
    } catch (error: any) {
      return {
        status: error.response.status,
        errorMessage: error.response.data.name,
      };
    }
  }, []);
};

export { useClaim };
