import styled from 'styled-components';

export const MainWrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: auto;
  width: 100%;
  height: 100vh;

  background-color: rgba(255, 255, 255, 0.3);

  @media (max-width: 768px) {
    padding: 0 5%;
  }
`;

export const Title = styled.h1`
  font-size: 7rem;
  margin: 0;
`;

export const Description = styled.h2`
  font-size: 1.5rem;
  text-align: center;
`;
