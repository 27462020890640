import styled from 'styled-components';

export const MainWrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  padding: 0 5%;
  width: 90%;
  height: 100%;

  svg {
    width: 100%;
    margin-top: 5%;
    overflow: visible;
  }

  @media (max-width: 768px) {
    justify-content: space-between;
    width: 100%;
    height: 100vh;

    margin: 0;
    padding: 0;

    svg {
      width: 90%;
      margin-top: 5%;
    }
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  padding: 0 5%;
  width: 90%;
  max-width: 900px;
  margin-top: 70px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    margin-top: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ContactUsWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

export const ContactUsText = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;
