import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  align-items: center;
  padding: 5%;
  width: calc(100% - 5%);
  max-width: 500px;
  justify-content: space-between;
  box-shadow: 0px 4px 24px 0px #00000030;
  border-radius: 16px 0px 0px 16px;
  height: 100%;
  @media (max-width: 768px) {
    width: calc(100% - 10%);
    max-width: 100%;
    padding: 10%;
    box-shadow: none;
    border-radius: 32px;
  }
`;
