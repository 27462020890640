import { BrowserRouter } from 'react-router-dom';
import GlobalStyle from './globalStyles';
import { IntlProvider } from './i18n';
import Router from './Router';

export default function App() {
  return (
    <IntlProvider>
      <GlobalStyle />
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </IntlProvider>
  );
}
