import { theme } from '../../assets/styles/theme';
import Button from '../Button/Button';
import {
  CenteredModal,
  CloseBtn,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalParagraph,
  ModalTitle,
  ModalWrapper,
  ParagaphWrapper,
  WalletIcon,
} from './Modal.styles';
interface IModal {
  setIsOpen: Function;
  brand: string;
}

const Modal = ({ setIsOpen, brand }: IModal) => {
  const buttonStyle = {
    backgroundColor: theme[brand].buttons.primary.bgColor,
    width: '155px',
  };

  return (
    <ModalWrapper data-testid="modal-wrapper" onClick={() => setIsOpen(false)}>
      <CenteredModal>
        <ModalContent data-testid="modal">
          <ModalHeader>
            <ModalTitle>Your claim has been sent!</ModalTitle>
            <CloseBtn data-testid="close-modal-button" onClick={() => setIsOpen(false)}>
              X
            </CloseBtn>
          </ModalHeader>
          <ModalBody>
            <WalletIcon />
            <ParagaphWrapper>
              <ModalParagraph>Claims are generally confirmed</ModalParagraph>
              <ModalParagraph>on the blockchain within 24 hours.</ModalParagraph>
            </ParagaphWrapper>
          </ModalBody>
          <ModalFooter>
            <Button style={buttonStyle} onClick={() => setIsOpen(false)}>
              Got it
            </Button>
          </ModalFooter>
        </ModalContent>
      </CenteredModal>
    </ModalWrapper>
  );
};

export { Modal };
