import { BlurWrapper, CenteredElement, LoadingSpinner, SpinnerContainer } from './Spinner.styles';

const SpinnerComponent = () => {
  return (
    <BlurWrapper>
      <CenteredElement>
        <SpinnerContainer>
          <LoadingSpinner data-testid="spinner" />
        </SpinnerContainer>
      </CenteredElement>
    </BlurWrapper>
  );
};
export { SpinnerComponent };
